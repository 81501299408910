.content-upload {
  margin-bottom: 1rem;
  
  img { 
    width: 398px;
    height: 126px; 
    object-fit: contain;
  }    
} 

:where(.css-17sses9).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select { 
    width: 398px;
    height: 126px; 
  }


