.logo {
  width: 200px;
}

.sidebar {
  width: 250px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: #0f172a;
  transition: width 300ms;
  z-index: 100;
  overflow: auto;
  padding: 0.25rem;
  overflow-y: auto;
  scrollbar-width: thin;

  /* Scroll */
  &::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(197, 197, 197);
    border-radius: 0.25rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(157, 156, 156);
    border-radius: 0.25rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: grey;
  }

  &-brand {
    padding: 1.5rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      display: flex;
      padding: 1.5rem;
      flex-direction: column;
      align-items: center;
      font-weight: 700;
      letter-spacing: 2px;
    }
  }

  &-menu {
    margin-top: 0.5rem;

    li {
      padding-left: 1rem;
    }

    img {
      height: 1rem;
    }
  }

  &-subtitle {
    font-size: 14px;
  }
}

.side-menu {
  span {
    padding: 0 12px;
  }

  img {
    margin-top: auto;
    margin-bottom: auto;
  }

  a.active {
    background-color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #021d49;
    border-radius: 30px 0px 0px 30px;
  }

  a span:first-child {
    font-size: 1.5rem;
    margin: auto;
  }
}

#nav-toggle:checked + .sidebar .sidebar-menu {
  display: none;
}

#nav-toggle:checked + .sidebar {
  width: 0;
  padding: 0;
}

#nav-toggle:checked + .sidebar .sidebar-brand,
#nav-toggle:checked + .sidebar li {
  padding-left: 1.2rem;
  text-align: center;
  display: none;
}

#nav-toggle:checked + .sidebar .sidebar-brand {
  height: 100px;
}

#nav-toggle:checked + .sidebar .sidebar-brand span {
  padding: 0;
}

#nav-toggle:checked + .sidebar li a {
  padding-left: 0rem;
}

#nav-toggle:checked + .sidebar li span {
  padding-right: 1rem;
}

#nav-toggle:checked + .sidebar li a span:last-child {
  display: none;
}

#nav-toggle:checked + .sidebar li a svg:last-child {
  display: none;
}

#nav-toggle:checked + .sidebar .sidebar-brand {
  margin-top: 1.5rem;
  padding: 0rem 0.25rem;
}

.logo {
  visibility: visible;
}

.mini {
  visibility: hidden;
}

#nav-toggle:checked ~ .main-content {
  margin-left: 0;
}

#nav-toggle:checked ~ .main-content header {
  width: 100%;
}

#nav-toggle {
  display: none;
} 

@media only screen and (max-width: 1280px) {

  
  .circle-red {
    top: -15px;
  }

  .logo {
    width: 160px;
  }

  .sidebar {
    width: 200px;
    padding-top: 100px;

    &-icon > svg {
      width: 1.25rem !important;
    }
    &-subtitle {
      font-size: 11px;
    }
  }

  .main {
    padding: 0 10px 0 150px;
    width: calc(100% - 30px);
  }

  #nav-toggle:checked ~ .main-content .main {
    padding: 0 25px;
  }

  .sidebar li a {
    padding-left: 0rem;
  } 
  
  .sidebar .sidebar-brand {
    padding: 0rem 0rem 0rem 0.5rem;
  }

  .main-content {
    margin-left: 70px;
    margin-top: 70px;
  }

  .main-content header {
    width: calc(100% - 60px);
    left: 70px;
  }

  #nav-toggle:checked ~ .main-content header {
    width: calc(100% - 60px);
  }

  .signout {
    span {
      display: none;
    }
  }

  .main-content header {
    left: 0;
  }

  #nav-toggle:checked ~ .main-content header {
    left: 0;
  }
}


@media only screen and (max-width: 1024px) { 

  .main-content header {
    width: calc(100% - 50px); 
  }

  .main {
    width: calc(100% - 5px)
  } 
} 

@media only screen and (max-width: 820px) {  
  .main-content header {
    width: 100%
  }

  #nav-toggle:checked ~ .main-content header {
    width: calc(100% - 60px);
  }
}

@media only screen and (max-width: 768px) {
  .main-content header {
    width: 100%; 
  }

  #nav-toggle:checked ~ .main-content header {
    width: 100%;
  } 
} 

// SubNav
.sidebar {
  &-nav {
    background-color: #2d3748;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: left 350ms ease-in-out;
    z-index: 10;

    &.active {
      left: 0;
    }
  }

  &-wrap {
    width: 100%;
  }

  &-content {
    margin: 0 0.5rem;

    &:hover {
      // background-color: #ffffff33;
      border-radius: 0.5rem;
    }
  }
  &-link {
    display: flex;
    color: #fff;
    align-items: center;
    list-style: none;
    text-decoration: none;
    font-size: 14px;
    padding: 0.5rem 0;
    margin: 1rem 0.5rem;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }
  }

  &-icon > svg {
    width: 1.5rem;
    margin: auto 0.5rem;
  }
}

.subnav {
  &-link {
    background: #2d3748;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 14px;

    &:hover {
      background-color: #ffffff33;
      cursor: pointer;
      margin: 0 0.5rem;
      border-radius: 0.5rem;
    }
  }
}

.active {
  background-color: #ffffff33;
  cursor: pointer;
  border-radius: 0.5rem;
}

.sidebar {
  &-item {
    padding: 0.35em;
    font-size: 1em;
    display: block;
    color: white;
    transition: background-color 0.3s;
    cursor: pointer;
    margin-bottom: 0.25rem;

    &:hover {
      background-color: rgb(255, 255, 255, 0.1);
      border-radius: 0.5em;
    }
  }

  &-title {
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-content {
    padding-top: 0.25em;
  }
}

.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.5s;
}

.sidebar-item.open .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}

.sidebar-content {
  padding-top: 0.25rem;
  height: 0;
  overflow: hidden;
}

.sidebar-item.open .sidebar-content {
  height: auto;
}
