.account {  
    &-logo {
        margin: auto;
    }

    &-title-label {
        margin-top: 2rem;
    }
}

:where(.css-dev-only-do-not-override-17sses9).ant-form-vertical .ant-form-item-label >label > .montserrat
{
    font-family: Montserrat;
}

