.setaccount {
    &-card {         
        max-width: 550px; 
        min-width: 300px;
        padding: 3rem 4rem;
        margin: 0 4rem ;
        flex: 1 1; 
        background-color: #F4FFF5;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
 
    }

    &-logo {
        margin: auto;
    }

    &-title-label {
        margin-top: 2rem;
    }

    &-contact {
        
        @media only screen and (max-width: 892px) { 
            display: none;
        }
    }
}

:where(.css-dev-only-do-not-override-17sses9).ant-form-vertical .ant-form-item-label >label > .montserrat
{
    font-family: Montserrat;
}

