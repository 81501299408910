.table {
  &-content {
    overflow-y: auto;
    height: 55vh;

    @media only screen and (max-width: 1280px) { 
        height: 42vh !important; 
    }

    border: 1px solid #dcdcdc;
    margin-top: 1rem;
    background: #f1f5f98a;
    border-radius: 0.25rem;

    /* Scroll */
    &::-webkit-scrollbar {
      width: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgb(197, 197, 197);
      border-radius: 0.25rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(157, 156, 156);
      border-radius: 0.25rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: grey;
    }

    thead th {
      position: sticky;
      top: 0; 
    }

    tbody tr { 

      &:hover {
        background-color: #e5eff98a;
      }
    }
  }

  &-pagination {
    display: flex;
    margin-top: 0.5rem;
  }

  &-rowsperpage {
    margin: 0.5rem;
    padding: 0.5rem;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 15px; 
}
th {
  background: #0d874e;
  color: #ffffff;
  text-align: center;
  border-bottom: 1px solid #9c9c9c;
  font-family: "Montserrat";
}
