.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 

.side {
  max-height: calc(100vh);
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden; 
  border-left: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 20px;
  z-index: 5;
} 

.side-parcel {
  max-height: calc(100vh);
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden; 
  border-left: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 20px;
  z-index: 5;
} 

.bg-light {
  background: rgba(241, 245, 249, 0.5411764706)
}

.transaction-table > tr > th {
  background: transparent;
  color: #9c9c9c;
  text-align: left; 
  border-bottom: none;
}

input[type="file"] {
  display: none;
}

button:disabled,
button[disabled],
button:hover:disabled,
button:hover[disabled]{  
  letter-spacing: 0.025em;
  border: none;
  opacity: 0.5;
}  
 
:where(.css-17sses9).ant-input-number {
  width: 100%;
}

/* ANTD */
:where(.css-dev-only-do-not-override-17sses9).ant-btn-primary,
:where(.css-17sses9).ant-btn-primary {
  background-color: #1677ff;
}

:where(.css-17sses9).ant-input,
:where(.css-17sses9).ant-select-single .ant-select-selector,
:where(.css-17sses9).ant-select-multiple .ant-select-selector, 
:where(.css-17sses9).ant-input-group .ant-input-group-addon,
:where(.css-17sses9).ant-picker {
  border-radius: 2px !important;
}  

:where(.css-17sses9).ant-form-item {
  margin-bottom: 20px;
}
:where(.css-17sses9).ant-input-group >.ant-input:last-child, 
:where(.css-17sses9).ant-input-group .ant-input-group-addon:last-child, 
:where(.css-17sses9).ant-input-group >.ant-input:first-child, 
:where(.css-17sses9).ant-input-group .ant-input-group-addon:first-child
 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
} 

:where(.css-17sses9).ant-btn { 
  border-radius: 3px;
}

:where(.css-17sses9).ant-picker-focused.ant-picker {
  box-shadow: none;
}

.ant-switch {
  font-feature-settings: "tnum","tnum";
  background-color: #00000040;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #000000d9;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-variant: tabular-nums;
  height: 22px;
  line-height: 1.5715;
  line-height: 22px;
  list-style: none;
  margin: 0;
  min-width: 44px;
  padding: 0;
  position: relative;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

/* Voucher */
.container-cc {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fff;
}

.card {
  width: 400px;
  height: 180px;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 10px 10px;
  position: relative;
}

.mains,
.copy-button {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}
.card::after {
  position: absolute;
  content: "";
  height: 40px;
  right: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 70px;
  background-color: #dc143c;
  width: 40px;
}

.card::before {
  position: absolute;
  content: "";
  height: 40px;
  left: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 70px;
  background-color: #dc143c;
  width: 40px;
}

.co-img img {
  width: 100px;
  height: 100px;
}

.vertical {
  border-left: 5px dotted black;
  height: 100px;
  position: absolute;
  left: 40%;
}

.content h1 {
  font-size: 35px;
  margin-left: -20px;
  color: #565656;
}

.content h1 span {
  font-size: 18px;
}
.content h2 {
  font-size: 18px;
  margin-left: -20px;
  color: #565656;
  text-transform: uppercase;
}

.content p {
  font-size: 16px;
  color: #696969;
  margin-left: -20px;
}

.copy-button {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.copy-button input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.copy-button button {
  padding: 5px 20px;
  background-color: #dc143c;
  color: #fff;
  border: 1px solid transparent;
}

.buy{
 position: absolute;
  content: "";
  bottom: 20px;
  left:20px;
  background-color: #dc143c;
 }