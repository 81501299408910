@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Inter", "Montserrat", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

span,
input,
h1,
select,
p {
  font-family: "Montserrat", "Segoe UI", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.z-1 {
  z-index: 1;
}

.a {
  color: #4f46e5;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

button:disabled,
button:hover:disabled,
button:hover[disabled],
button[disabled] {
  color: #9c9c9c !important;
}

// Main Content
main {
  max-height: calc(100vh);
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
}

.main-content {
  transition: margin-left 300ms;
  margin-left: 250px;
  min-height: 100vh;
  height: 100vh;
}

.main {
  padding: 100px 25px 0;
}

// Sign up
.login-header {
  opacity: 0.12;
  color: #fff;
  opacity: 0.12;
  font-size: 3rem;
  font-weight: 800;
}

.img-logo {
  width: 30rem;
}

.dots {
  width: 10rem;
  right: 0;
  top: 0;
  position: absolute;
  opacity: 0.05;
}

.circle-up {
  width: 22rem;
  left: -8rem;
  top: 0;
  position: absolute;
  opacity: 0.05;
}

.circle-down {
  width: 22rem;
  right: -5rem;
  bottom: -1rem;
  position: absolute;
  opacity: 0.05;
}

.circle-red {
  width: 30rem;
  left: -5rem;
  top: 58px;
  position: absolute;
  opacity: 0.2;
  z-index: 0;
}

.circle-green {
  width: 30rem;
  right: -5rem;
  bottom: -1rem;
  position: absolute;
  opacity: 0.2;
}

.search {
  border-radius: 0.25rem;
  border: 1px solid #d2d2d2;
  width: 100%;

  &-content {
    position: relative;
    flex: none;
    margin: auto 0;
    padding: 1rem;
  }

  &-bar {
    display: flex;
    justify-content: end;
    width: 100%;

    // @media only screen and (max-width: 768px) {
    //   display: none;
    // }
  }
}

.content-end {
  display: flex;
  justify-content: end;
  width: 100%;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.minicard {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem; /* 8px */
  padding: 1rem; /* 16px */
  border: 2px solid rgb(241 245 249);
  border-radius: 0.25rem; /* 4px */
}

.status {
  &-style {
    display: flex;
    justify-content: center;
    border-radius: 0.125rem;
    align-items: center;
    width: 110px;
    border-width: 1px;
  }

  &-label {
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.25rem;
    text-transform: capitalize;
  }
}

.csvlink {
  font-size: 1rem;
  margin-left: 0.5rem;
  margin-right: 1rem;
  font-family: "Montserrat", "Segoe UI", sans-serif;
}

:where(.css-dev-only-do-not-override-17sses9).ant-tabs
  .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-17sses9).ant-tabs
  .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-17sses9).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-17sses9).ant-tabs
  .ant-tabs-tab-remove:active {
  color: #0d874e !important;
}
:where(.css-dev-only-do-not-override-17sses9).ant-tabs .ant-tabs-tab-btn:hover {
  color: #0d874e !important;
}

:where(.css-dev-only-do-not-override-17sses9).ant-tabs .ant-tabs-tab-btn {
  color: #6d6d6d;
}

:where(.css-dev-only-do-not-override-17sses9).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #0d874e !important;
  font-weight: 500;
}

:where(.css-dev-only-do-not-override-17sses9).ant-tabs .ant-tabs-ink-bar {
  background: #0d874e !important;
}

:where(.css-17sses9).ant-select-multiple .ant-select-selector {
  max-height: 150px;
  overflow-y: auto;
  border-radius: 3px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(157, 156, 156);
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
}
