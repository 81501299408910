.content {
    &-card {
        display: -webkit-inline-box;
        margin: 1.5rem auto;
    }

    &-img {
        width: 365px;
        height: 126px;
    }

    &-menu {
        display: flex;
        flex-direction: column;
        border: 1px solid #e8e7e7;
        border-bottom: none;

        &-container {
            border-bottom: 1px solid #e8e7e7;
        }
    }

    &-icons {
        font-size: 1.25rem;
        margin: 0.65rem .5rem;
        color: #b9bab9; 
        cursor: pointer;
    
        &:hover,
        &:focus {
            color: #43A349;
        }
    }

    &-icons-disable {
        font-size: 1.25rem;
        margin: 0.65rem .5rem;
        color: #b9bab9;  
    
        &:hover,
        &:focus {
            color: #b9bab9;
        }
    }
}