.user-admin {
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin-top: 1rem;
    }
      
    td {
        border-bottom: 1px solid #dddddd;    
        text-align: center;
        padding: 0.5rem;
    }
      
    tr:first-child {
        background-color: rgba(241, 245, 249, 0.5411764706); 
        box-shadow: 0px 15px 10px -15px #dfdfdf;
    }
    tr > th {
        text-align: center;
        padding: 0.9rem;
    }

    tr > th:first-child, td:first-child {
        text-align: left;
    } 
      
}