header {
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    position: fixed; 
    align-items: center;
    width: calc(100% - 250px); 
    top:0;
    z-index: 100; 
    transition: left 300ms;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
  
.logout{
    &-icon { 
        vertical-align: top; 
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 1rem;
        color: #000;
    }
}